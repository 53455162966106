import { useWindowSize } from "@uidotdev/usehooks";

const ImpactoverseFor = () => {
  const windowSize = useWindowSize();
  return (
    <>
      {/* <!-- Process / CTA --> */}
      <section className="py-24 pt-32 dark:bg-jacarta-800 bg-light-base">
        <div className="container">
          <div className="mx-auto text-center">
            <h2 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
              Using tech <span className="animate-gradient">for good</span>
            </h2>
            {/* <h3 className="py-2 block font-display text-xl font-medium group-hover:text-accent dark:text-white text-center uppercase">
              Using tech for good
            </h3> */}

            {/* Desktop View */}
            {windowSize?.width >= 1024 && (
              // <p className="text-lg dark:text-jacarta-300">
              //   At the intersection of people, planet, profit, and purpose,
              //   Impactoverse is an ecosystem leveraging technology and
              //   creativity for real-world positive outcomes. Through engaging,
              //   interactive, and immersive technologies, we are transforming the
              //   ways in which we raise awareness of SDG-aligned activity and
              //   enable actions to address pressing environmental and social
              //   issues. We’re your portal to a global movement, where people and
              //   organizations come together for mutual benefit and real impact.
              // </p>
              <p className="text-lg dark:text-jacarta-300">
                At the intersection of people, planet, profit, and purpose,
                Impactoverse is an ecosystem leveraging technology and
                creativity for real-world positive outcomes. Through engaging,
                interactive, and immersive technologies, we are transforming the
                ways in which we raise awareness of SDG-aligned activity and
                enable actions to address pressing environmental and social
                issues. We’re your portal to a global movement, where people and
                organizations come together for mutual benefit and real impact.
              </p>
            )}
          </div>
          {/* Mobile View */}
          {windowSize?.width <= 1024 && (
            <>
              <p className="my-2 text-lg dark:text-jacarta-300 text-center">
                At the intersection of people, planet, profit, and purpose,
                Impactoverse is an ecosystem leveraging technology and
                creativity for real-world positive outcomes.
              </p>
              <p className="my-2 text-lg dark:text-jacarta-300 text-center">
                Through engaging, interactive, and immersive technologies, we
                are transforming the ways in which we raise awareness of
                SDG-aligned activity and enable actions to address pressing
                environmental and social issues. We’re your portal to a global
                movement, where people and organizations come together for
                mutual benefit and real impact.
              </p>
            </>
          )}

          {/* <ImpactoverseForTextAnimation /> */}
          {/* End mx-auto */}

          {/* <CallToActions /> */}
        </div>
      </section>
      {/* <!-- end process / cta -->  */}
    </>
  );
};

export default ImpactoverseFor;
