import React from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import Link from 'next/link';

export default function TakeMeThere() {
  const windowSize = useWindowSize();
  return (
    <section className="py-5">
      <div className="container">
        <div className="mx-auto mt-8 mb-8 text-center">
          <h2 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
            TAKE ME <span className="animate-gradient">THERE</span>
          </h2>
        </div>
        {/* Desktop View of Buuton  */}
        {windowSize.width > 1024 && 
        <div className="mb-10 lg:flex lg:flex-nowrap justify-center flex-wrap">
          <Link
               href="/world-of-impact"
          
            className="inline-block bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark border-gradient-animation mb-4 lg:mb-0 lg:mr-4"
          >
            WORLD OF IMPACT
          </Link>
          <Link
            href="/impact-campaigns"
        
      
            className="inline-block bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark border-gradient-animation mb-4 lg:mb-0 lg:mr-4"
          >
            IMPACT CAMPAIGNS
          </Link>
    
        </div> }
        {/* Mobile View of Buuton  */}
        {windowSize.width < 1024 && (
          <div>
            <div className="flex justify-center">
              <Link
             href="/world-of-impact"
                className="block bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark border-gradient-animation mb-4 lg:mb-4 lg:mr-4 w-11/12 "
              >
                WORLD OF IMPACT
              </Link>
            </div>
            <div className="flex justify-center">
              <Link
                   href="/impact-campaigns"
                className="block bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark border-gradient-animation mb-4 lg:mb-4 lg:mr-4 w-11/12"
              >
                IMPACT CAMPAIGNS
              </Link>
            </div>
       
          </div>
        )}
      </div>
    </section>
  );
}
