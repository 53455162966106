// import SingleProcessBlock from "../cryto-trading/SingleProcessBlock";
import Image from "next/image";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import React, { useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import SingleProcessBlock from "../cryto-trading/SingleProcessBlock";
import HeadingWithAnimation from "./headingWithAnimation";
import ImpactoverseForTextAnimation from "./impactoverseForTextAnimation";
import Link from "next/link";
import FeaturesGrid from "./feature_grid";
import OurEcosystemGrid from "./ourEcosystem";
import FaqCollabHub from "../home/CollabFaQs";
import BeniftContent from "../dao/BeniftContent";
import { with_our_partner } from "../../data/team_data";
import { features } from "../../data/home_data";

const HomeSection2 = () => {
  const [videoModalBrand, setvideoModalBrand] = useState(false);
  const [videoModalCreator, setvideoModalCreator] = useState(false);
  const windowSize = useWindowSize();
  const [videoModalImpactIntiative, setvideoModalImpactIntiative] =
    useState(false);

  const [videoModalSupporters, setvideoModalSupporters] = useState(false);

  const handleVideo = () => {
    var iframes = document.querySelectorAll("iframe");
    const elementsArray = Array.from(iframes);
    for (var i = 0; i < elementsArray.length; i++) {
      const src = elementsArray[i].src;
      if (src && src.includes("youtube.com")) {
        // Replace "autoplay=1" with "autoplay=0" to stop the video
        elementsArray[i].src = src.replace("autoplay=1", "autoplay=0");
      }
    }
  };
  const servicesData = [
    {
      title: "Immersive Impact-Aligned Education",
      description:
        "Plan it, create it, launch it. Collaborate seamlessly with all the organization and hit your marketing goals every month with our marketing plan.",
      image: "/images/wallets/Immersive.svg",
      bgColor: "bg-accent-dark",
    },
    {
      title: "Innovation, Project and Brand Showcases",
      description:
        "Protect your organization, devices and stay compliant with our structured workflows and custom permissions made for you.",
      image: "/images/wallets/Innovation.svg",
      bgColor: "bg-green",
    },
    {
      title: "AI & Emerging Tech Resource Made Easy",
      description:
        "Auto-assign tasks, send Slack messages, and much more. Now power up with hundreds of new templates to help you get started.",
      image: "/images/wallets/AI.svg",
      bgColor: "bg-orange",
    },
    {
      title: "Impact Campaigns & Collaborations",
      description:
        "Audit-proof software built for critical financial operations like month-end close and quarterly budgeting.",
      image: "/images/wallets/Impact.svg",
      bgColor: "bg-blue",
    },
    {
      title: "Recognition & Reward for Impact",
      description:
        "Craft beautiful, delightful experiences for both marketing and product with real cross-company collaboration.",
      image: "/images/wallets/Recognition.svg",
      bgColor: "bg-accent-dark",
    },
    {
      title: "Digital Verified Certification for Impact",
      description:
        "Keep your company’s lights on with customizable, iterative, and structured workflows built for all efficient teams and individual.",
      image: "/images/wallets/Digital.svg",
      bgColor: "bg-green",
    },
  ];

  return (
    <>
      {/* <!-- Process / CTA --> */}
      <section className="py-24">
        <div className="container">
          {/* <div className="mx-auto mb-8 text-center slide-in-left ">
            <h2 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
              Take me <span className="animate-gradient">There</span>
            </h2>
            <p className="text-lg dark:text-jacarta-300">
              We connect impact-aligned individuals and organizations globally,
              increasing awareness AND action around problems being faced by
              people and the planet, offering innovative ways to work (and
              play!) together to solve them.
            </p>
          </div> */}
          <HeadingWithAnimation />

          <FeaturesGrid features={features} />
          {/* <div className="mb-0 lg:flex lg:flex-nowrap lg:space-x-8">
            <SingleProcessBlock
              width={"lg:w-1/2"}
              list={[
                {
                  id: 3,
                  step: "03",
                  title:
                    "Impactful Innovations, Experiences, People, Projects & Brands",
                  text: ``,
                  icon: (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        zoomAndPan="magnify"
                        viewBox="0 0 36 36.000001"
                        height="48"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                        className="absolute top-5 right-5 fill-accent/25 dark:fill-accent/50"
                      >
                        <defs>
                          <clipPath id="c6926307d2">
                            <path d="M 6.179688 4 L 28 4 L 28 31 L 6.179688 31 Z M 6.179688 4 " />
                          </clipPath>
                          <clipPath id="d6783ea523">
                            <path d="M 6.179688 1.167969 L 28 1.167969 L 28 6 L 6.179688 6 Z M 6.179688 1.167969 " />
                          </clipPath>
                          <clipPath id="e45895ef2e">
                            <path d="M 6.179688 29 L 28 29 L 28 34 L 6.179688 34 Z M 6.179688 29 " />
                          </clipPath>
                          <clipPath id="55ff3b6f9c">
                            <path d="M 32 11 L 35.367188 11 L 35.367188 17 L 32 17 Z M 32 11 " />
                          </clipPath>
                        </defs>

                        <g clipPath="url(#c6926307d2)">
                          <path
                            d="M 26.480469 30.675781 L 6.726562 30.675781 C 6.398438 30.675781 6.179688 30.457031 6.179688 30.128906 L 6.179688 5.042969 C 6.179688 4.714844 6.398438 4.5 6.726562 4.5 L 26.480469 4.5 C 26.808594 4.5 27.027344 4.714844 27.027344 5.042969 L 27.027344 9.40625 C 27.027344 9.734375 26.808594 9.953125 26.480469 9.953125 C 26.148438 9.953125 25.929688 9.734375 25.929688 9.40625 L 25.929688 5.589844 L 7.277344 5.589844 L 7.277344 29.585938 L 25.929688 29.585938 L 25.929688 28.492188 C 25.929688 28.167969 26.148438 27.949219 26.480469 27.949219 C 26.808594 27.949219 27.027344 28.167969 27.027344 28.492188 L 27.027344 30.128906 C 27.027344 30.457031 26.808594 30.675781 26.480469 30.675781 Z M 26.480469 30.675781 "
                            fillOpacity="1"
                            fillRule="nonzero"
                          />
                        </g>
                        <g clipPath="url(#d6783ea523)">
                          <path
                            d="M 26.480469 5.589844 L 6.726562 5.589844 C 6.398438 5.589844 6.179688 5.371094 6.179688 5.042969 L 6.179688 2.863281 C 6.179688 1.933594 6.890625 1.226562 7.824219 1.226562 L 25.382812 1.226562 C 26.316406 1.226562 27.027344 1.933594 27.027344 2.863281 L 27.027344 5.042969 C 27.027344 5.371094 26.808594 5.589844 26.480469 5.589844 Z M 7.277344 4.5 L 25.929688 4.5 L 25.929688 2.863281 C 25.929688 2.535156 25.710938 2.316406 25.382812 2.316406 L 7.824219 2.316406 C 7.496094 2.316406 7.277344 2.535156 7.277344 2.863281 Z M 7.277344 4.5 "
                            fillOpacity="1"
                            fillRule="nonzero"
                          />
                        </g>
                        <path
                          d="M 17.699219 3.953125 L 15.503906 3.953125 C 15.175781 3.953125 14.957031 3.734375 14.957031 3.40625 C 14.957031 3.082031 15.175781 2.863281 15.503906 2.863281 L 17.699219 2.863281 C 18.03125 2.863281 18.25 3.082031 18.25 3.40625 C 18.25 3.734375 18.03125 3.953125 17.699219 3.953125 Z M 17.699219 3.953125 "
                          fillOpacity="1"
                          fillRule="nonzero"
                        />
                        <path fill="none" d="M0 0h24v24H0z" />
                        <g clipPath="url(#e45895ef2e)">
                          <path
                            d="M 25.382812 33.945312 L 7.824219 33.945312 C 6.890625 33.945312 6.179688 33.238281 6.179688 32.3125 L 6.179688 30.128906 C 6.179688 29.800781 6.398438 29.585938 6.726562 29.585938 L 26.480469 29.585938 C 26.808594 29.585938 27.027344 29.800781 27.027344 30.128906 L 27.027344 32.3125 C 27.027344 33.238281 26.316406 33.945312 25.382812 33.945312 Z M 7.277344 30.675781 L 7.277344 32.3125 C 7.277344 32.636719 7.496094 32.855469 7.824219 32.855469 L 25.382812 32.855469 C 25.710938 32.855469 25.929688 32.636719 25.929688 32.3125 L 25.929688 30.675781 Z M 7.277344 30.675781 "
                            fillOpacity="1"
                            fillRule="nonzero"
                          />
                        </g>
                        <path
                          d="M 17.699219 32.3125 L 15.503906 32.3125 C 15.175781 32.3125 14.957031 32.09375 14.957031 31.765625 C 14.957031 31.4375 15.175781 31.21875 15.503906 31.21875 L 17.699219 31.21875 C 18.03125 31.21875 18.25 31.4375 18.25 31.765625 C 18.25 32.09375 18.03125 32.3125 17.699219 32.3125 Z M 17.699219 32.3125 "
                          fillOpacity="1"
                          fillRule="nonzero"
                        />
                        <path
                          d="M 23.351562 17.75 C 22.527344 17.75 21.816406 17.09375 21.707031 16.277344 L 21.484375 14.097656 C 21.375 13.222656 22.035156 12.40625 22.96875 12.296875 L 25.710938 12.023438 L 26.257812 17.476562 L 23.515625 17.75 C 23.460938 17.75 23.40625 17.75 23.351562 17.75 Z M 24.722656 13.222656 L 23.078125 13.386719 C 22.804688 13.441406 22.527344 13.660156 22.582031 13.988281 L 22.804688 16.167969 C 22.859375 16.441406 23.132812 16.660156 23.40625 16.660156 L 25.050781 16.496094 Z M 24.722656 13.222656 "
                          fillOpacity="1"
                          fillRule="nonzero"
                        />
                        <path
                          d="M 32.351562 19.605469 C 32.183594 19.605469 32.019531 19.605469 31.855469 19.550781 L 25.109375 17.421875 L 24.613281 12.351562 L 30.8125 8.96875 C 31.308594 8.699219 31.910156 8.699219 32.351562 8.96875 C 32.84375 9.242188 33.171875 9.734375 33.226562 10.277344 L 33.996094 17.859375 C 34.050781 18.40625 33.832031 18.949219 33.390625 19.277344 C 33.0625 19.441406 32.734375 19.605469 32.351562 19.605469 Z M 32.183594 18.460938 C 32.351562 18.511719 32.570312 18.460938 32.679688 18.351562 C 32.84375 18.242188 32.898438 18.078125 32.898438 17.859375 L 32.128906 10.277344 C 32.128906 10.117188 32.019531 9.953125 31.855469 9.84375 C 31.691406 9.734375 31.527344 9.734375 31.363281 9.84375 L 25.820312 12.898438 L 26.203125 16.496094 Z M 32.183594 18.460938 "
                          fillOpacity="1"
                          fillRule="nonzero"
                        />
                        <g clipPath="url(#55ff3b6f9c)">
                          <path
                            d="M 32.734375 16.277344 L 32.296875 11.914062 L 32.84375 11.859375 C 34.050781 11.75 35.148438 12.625 35.257812 13.824219 C 35.367188 15.023438 34.488281 16.113281 33.28125 16.222656 Z M 33.503906 13.058594 L 33.667969 14.914062 C 33.996094 14.695312 34.160156 14.3125 34.105469 13.933594 C 34.105469 13.550781 33.832031 13.222656 33.503906 13.058594 Z M 33.503906 13.058594 "
                            fillOpacity="1"
                            fillRule="nonzero"
                          />
                        </g>
                        <path
                          d="M 25.101562 17.042969 L 26.191406 16.9375 L 26.433594 19.378906 L 25.339844 19.484375 Z M 25.101562 17.042969 "
                          fillOpacity="1"
                          fillRule="nonzero"
                        />
                        <path
                          d="M 27.339844 17.753906 L 28.429688 17.648438 L 28.582031 19.167969 L 27.488281 19.273438 Z M 27.339844 17.753906 "
                          fillOpacity="1"
                          fillRule="nonzero"
                        />
                        <path
                          d="M 15.503906 19.769531 C 12.488281 19.769531 10.019531 17.3125 10.019531 14.3125 C 10.019531 11.316406 12.488281 8.859375 15.503906 8.859375 C 18.523438 8.859375 20.992188 11.316406 20.992188 14.3125 C 20.992188 17.3125 18.523438 19.769531 15.503906 19.769531 Z M 15.503906 9.953125 C 13.089844 9.953125 11.117188 11.914062 11.117188 14.3125 C 11.117188 16.714844 13.089844 18.675781 15.503906 18.675781 C 17.917969 18.675781 19.894531 16.714844 19.894531 14.3125 C 19.894531 11.914062 17.917969 9.953125 15.503906 9.953125 Z M 15.503906 9.953125 "
                          fillOpacity="1"
                          fillRule="nonzero"
                        />
                        <path
                          d="M 22.636719 30.675781 L 10.019531 30.675781 C 9.691406 30.675781 9.46875 30.457031 9.46875 30.128906 L 9.46875 23.585938 C 9.46875 21.457031 11.171875 19.769531 13.3125 19.769531 L 16.054688 19.769531 C 18.359375 19.769531 20.5 20.859375 21.871094 22.710938 C 22.035156 22.929688 21.980469 23.3125 21.761719 23.476562 C 21.542969 23.640625 21.15625 23.585938 20.992188 23.367188 C 19.839844 21.785156 17.976562 20.859375 16.054688 20.859375 L 13.3125 20.859375 C 11.773438 20.859375 10.566406 22.058594 10.566406 23.585938 L 10.566406 29.585938 L 22.089844 29.585938 L 22.089844 26.855469 C 22.089844 26.695312 22.089844 26.53125 22.089844 26.367188 C 22.089844 26.039062 22.308594 25.820312 22.582031 25.765625 C 22.914062 25.710938 23.132812 25.984375 23.1875 26.257812 C 23.1875 26.476562 23.1875 26.640625 23.1875 26.855469 L 23.1875 30.128906 C 23.1875 30.457031 22.96875 30.675781 22.636719 30.675781 Z M 22.636719 30.675781 "
                          fillOpacity="1"
                          fillRule="nonzero"
                        />
                        <path
                          d="M 25.382812 26.855469 L 16.054688 26.855469 C 14.847656 26.855469 13.859375 25.875 13.859375 24.675781 C 13.859375 23.476562 14.847656 22.496094 16.054688 22.496094 L 24.285156 22.496094 C 24.613281 22.496094 24.832031 22.277344 24.832031 21.949219 L 24.832031 20.3125 C 24.832031 19.386719 25.546875 18.675781 26.480469 18.675781 L 27.578125 18.675781 C 28.507812 18.675781 29.222656 19.386719 29.222656 20.3125 L 29.222656 23.039062 C 29.222656 25.167969 27.523438 26.855469 25.382812 26.855469 Z M 16.054688 23.585938 C 15.449219 23.585938 14.957031 24.074219 14.957031 24.675781 C 14.957031 25.277344 15.449219 25.765625 16.054688 25.765625 L 25.382812 25.765625 C 26.917969 25.765625 28.125 24.566406 28.125 23.039062 L 28.125 20.3125 C 28.125 19.984375 27.90625 19.769531 27.578125 19.769531 L 26.480469 19.769531 C 26.148438 19.769531 25.929688 19.984375 25.929688 20.3125 L 25.929688 21.949219 C 25.929688 22.875 25.21875 23.585938 24.285156 23.585938 Z M 16.054688 23.585938 "
                          fillOpacity="1"
                          fillRule="nonzero"
                        />
                      </svg>
                    </>
                  ),
                },
                {
                  id: 2,
                  step: "02",
                  title: "Connect, Learn, Collaborate & Co-Create for Impact ",
                  text: ``,
                  icon: (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="48"
                        height="48"
                        className="absolute top-5 right-5 fill-accent/25 dark:fill-accent/50"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M9.33 11.5h2.17A4.5 4.5 0 0 1 16 16H8.999L9 17h8v-1a5.578 5.578 0 0 0-.886-3H19a5 5 0 0 1 4.516 2.851C21.151 18.972 17.322 21 13 21c-2.761 0-5.1-.59-7-1.625L6 10.071A6.967 6.967 0 0 1 9.33 11.5zM5 19a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v9zM18 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm-7-3a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
                      </svg>
                    </>
                  ),
                },
              ]}
            />
          </div> */}
          {/** **/}
          <br></br>
          <BeniftContent />

          {/* End mx-auto */}
          {/* <CallToActions /> */}
        </div>
        {/* Tab of Copy from BenifitContent */}
        {/* OUR ECOSYSTEM */}
      </section>
      {/* <GridWithIcons /> */}
      <section className="relative py-8">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image
            width={1519}
            height={935}
            src="/images/gradient.jpg"
            alt="gradient"
            className="h-full w-full object-cover"
          />
        </picture>
        <picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:block">
          <Image
            width={1519}
            height={935}
            src="/images/gradient_dark.jpg"
            alt="gradient dark"
            className="h-full w-full object-cover"
          />
        </picture>

        <div className="container mx-auto max-w-screen-xl">
          <div className="lg:flex lg:space-x-12">
            <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <Image
                width={1519}
                height={935}
                src="/images/gradient.jpg"
                alt="gradient"
                className="h-full w-full object-cover"
              />
            </picture>
            <picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:block">
              <Image
                width={1519}
                height={935}
                src="/images/gradient_dark.jpg"
                alt="gradient dark"
                className="h-full w-full object-cover"
              />
            </picture>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
            {servicesData.map((service, index) => (
              <div
                key={index}
                className="rounded-2.5xl shadow-md p-6 bg-white dark:bg-jacarta-700"
              >
                <div className="flex justify-center items-center mb-4 w-12 h-12 rounded-full bg-primary-100 lg:h-16 lg:w-16 dark:bg-primary-900">
                  <div className="rounded-xl text-center flex-1 m-4">
                    <div className="flex items-center justify-center">
                      <div
                        className={`rounded-full w-20 h-20 m-4 flex items-center justify-center ${service.bgColor}`}
                      >
                        <Image
                          width={86}
                          height={86}
                          src={service.image}
                          className=",-4"
                          alt="wallet"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-white">
                  {service.title}
                </h3>
                {/* <p className="text-gray-500 dark:text-gray-400">
                {service.description}
              </p> */}
              </div>
            ))}
          </div>

          <div className="container">
            <div className="lg:flex lg:space-x-12">
              <div className="mb-12 lg:w-full"></div>
            </div>
            <div className="lg:flex lg:space-x-12">
              <div className="mb-12">
                <div className="rounded-2.5xl ">
                  <h3 className="font-display text-xl text-jacarta-700 dark:text-white lg:text-2xl xl:text-3xl text-center uppercase mb-4">
                    WE WORK WITH EXPERTS TO OFFER CURRICULUM-ALIGNED
                    SUSTAINABILITY RESOURCES FOR PARENTS, STUDENTS AND
                    EDUCATORS!
                  </h3>

                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-[1.875rem] lg:grid-cols-5 mb-12">
                    {with_our_partner.map((item) => {
                      const { id, image, title } = item;
                      return (
                        <div
                          className="flex flex-col items-center justify-center"
                          key={id}
                        >
                          <div
                            className="relative rounded-2.5xl mb-6 h-[9.125rem] w-[9.125rem] bg-cover"
                            style={{ backgroundImage: `url(${image})` }}
                          >
                            {/* Overlay content goes here */}
                          </div>
                          <div className="rounded-2.5xl flex items-center justify-center w-[9.125rem] h-28">
                            <span className="dark:text-jacarta-300 text-md text-center font-semibold">
                              {title}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className={`relative p-5 rounded-2.5xl border border-jacarta-100 bg-white transition-shadow hover:shadow-xl dark:border-jacarta-50 dark:bg-jacarta-700 `}
                  >
                    <h3 className="font-display text-2xl text-jacarta-700 dark:text-white lg:text-3xl xl:text-4xl text-center uppercase mb-4">
                      THE HARMONY PROJECT
                    </h3>
                    <h3 className="block font-display text-xl font-medium group-hover:text-accent dark:text-white text-center">
                      Work on nature-based curriculum aligned programmes.
                      Learning from nature, not just about nature. Transforming
                      education and integrating solutions to meet the challenges
                      that face the next generation.
                    </h3>
                    <div className="flex items-center justify-center mt-4"></div>
                    {/* <p className="dark:text-jacarta-300">{item.text}</p> */}
                  </div>

                  {/* <Link
                  href="https://impactdots.world/"
                  target="_blank"
                  className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark "
                >
                  FIND OUT MORE
                </Link> */}
                </div>

                {/* <div className="rounded-2.5xl bg-white p-12 dark:bg-jacarta-700">
              <span className="mb-2 mt-1 block font-display text-xl font-medium group-hover:text-accent dark:text-white transition transition-colors">
                WORLD OF IMPACT
              </span>
              <p className="mb-8 text-lg leading-normal dark:text-jacarta-300">
                Dive into a world of amazing experiences, games, innovations and
                opportunities for impact that rewards you!
              </p>
              <a
                href="/world-of-impact"
                target="_blank"
                className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
              >
                Find Out More
              </a>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- end process / cta -->  */}
    </>
  );
};

export default HomeSection2;
