import { useRouter } from "next/router";
import pathChecking from "../../utils/pathChecking";
import Image from "next/image";
import { useWindowSize } from "@uidotdev/usehooks";

const testimonial_data = [
  {
    desc: "Inform, inspire, connect, and collaborate for",
    second: " meaningful action.",
    id: "0",
    image: "partner_dao_1",
  },
  {
    desc: "Addressing the world’s biggest environmental ",
    id: "1",
    second: "and social challenges.",
    image: "partner_dao_2",
  },
  {
    desc: "Fun, interactive, and immersive technology for real- world",
    id: "2",
    second: "positive outcomes and impact.",

    image: "partner_dao_3",
  },
  {
    desc: "Inform, inspire, connect, and collaborate for",
    second: " meaningful action.",
    id: "3",
    image: "partner_dao_1",
  },
  {
    desc: "Addressing the world’s biggest environmental ",
    id: "4",
    second: "and social challenges.",
    image: "partner_dao_2",
  },
  {
    desc: "Fun, interactive, and immersive technology for real- world",
    id: "5",
    second: "positive outcomes and impact.",

    image: "partner_dao_3",
  },
  {
    desc: "Inform, inspire, connect, and collaborate for",
    second: " meaningful action.",
    id: "6",
    image: "partner_dao_1",
  },
  {
    desc: "Addressing the world’s biggest environmental ",
    id: "7",
    second: "and social challenges.",
    image: "partner_dao_2",
  },
  {
    desc: "Fun, interactive, and immersive technology for real- world",
    id: "8",
    second: "positive outcomes and impact.",

    image: "partner_dao_3",
  },
  {
    desc: "Inform, inspire, connect, and collaborate for",
    second: " meaningful action.",
    id: "9",
    image: "partner_dao_1",
  },
  {
    desc: "Addressing the world’s biggest environmental ",
    id: "10",
    second: "and social challenges.",
    image: "partner_dao_2",
  },
  {
    desc: "Fun, interactive, and immersive technology for real- world",
    id: "11",
    second: "positive outcomes and impact.",

    image: "partner_dao_3",
  },
  {
    desc: "Inform, inspire, connect, and collaborate for",
    second: " meaningful action.",
    id: "12",
    image: "partner_dao_1",
  },
  {
    desc: "Addressing the world’s biggest environmental ",
    id: "13",
    second: "and social challenges.",
    image: "partner_dao_2",
  },
  {
    desc: "Fun, interactive, and immersive technology for real- world",
    id: "14",
    second: "positive outcomes and impact.",

    image: "partner_dao_3",
  },
  {
    desc: "Inform, inspire, connect, and collaborate for",
    second: " meaningful action.",
    id: "15",
    image: "partner_dao_1",
  },
  {
    desc: "Addressing the world’s biggest environmental ",
    id: "16",
    second: "and social challenges.",
    image: "partner_dao_2",
  },
  {
    desc: "Fun, interactive, and immersive technology for real- world",
    id: "17",
    second: "positive outcomes and impact.",

    image: "partner_dao_3",
  },
  {
    desc: "Inform, inspire, connect, and collaborate for",
    second: " meaningful action.",
    id: "18",
    image: "partner_dao_1",
  },
  {
    desc: "Addressing the world’s biggest environmental ",
    id: "19",
    second: "and social challenges.",
    image: "partner_dao_2",
  },
  {
    desc: "Fun, interactive, and immersive technology for real- world",
    id: "20General Partner at Entrepreneur",
    second: "positive outcomes and impact.",

    image: "partner_dao_3",
  },
];

const HeroSliderComponent = () => {
  const route = useRouter();
  const windowSize = useWindowSize();

  return (
    <>
      <div className="flex animate-marquee space-x-8">
        {testimonial_data.map((item, index) => {
          const { id, desc, second } = item;
          return (
            <div
              className="flex  items-center justify-center"
              key={index}
            >
            
                {windowSize.width > 1024 ? (
                    <div className="testimonial-item flex flex-wrap rounded-2.5xl bg-white pl-0 pr-0 pt-10 pb-10 dark:bg-jacarta-700 lg:flex-nowrap border dark:border-0 border-jacarta-100 m-5 justify-center w-600">
                  <p className="block  w-10/12 font-display text-xl font-medium group-hover:text-accent dark:text-white text-center ">
                    {desc} {"\n"} <span>{second}</span>
                  </p>
                  </div>
                ) : (
                  // Else statement content goes here
                 
                    <div className="testimonial-item flex flex-wrap rounded-2.5xl bg-white pl-0 pr-0 pt-10 pb-10 dark:bg-jacarta-700 lg:flex-nowrap border dark:border-0 border-jacarta-100 m-5 justify-center w-900">
                   <p className="block w-full font-display text-lg font-medium group-hover:text-accent dark:text-white text-center pl-10 pr-10">
                    {desc} <span>{second}</span>
                  </p>
                    </div>
                )}
      
            </div>
          );
        })}
      </div>
    </>
  );
};

export default HeroSliderComponent;
