const news_data = [
  {
    id: "5Mint your own Tezos collections",
    text: "Education and community campaigns fail to actively engage and equip potential changemakers to effectively gain insights, collaborate and act on sustainability challenges in an effective way that makes learning and problem-solving interactive, relatable and actionable. The use of AI is coming into question with a scourge of misinformation and deep fake content, which invalidates a base of knowledge upon which future generations can build on.",
    title: "Mint your own Tezos collections",
    image: "/images/blog/post_1.jpg",
    date: "5 Feb",
    time: "3 min read",
  },
  {
    id: "0Mint your own Tezos collections",

    title: "Problem",
    image: "/images/blog/problem.jpg",
    date: "5 Feb",
    time: "3 min read",
    text: "Education and impact campaigns are not successfully, or fully, engaging potential changemakers in tackling the sustainability challenges we face. These initiatives struggle to make learning and problem-solving interactive, relatable, and actionable. Additionally, the rise of AI has brought about a surge of misinformation and deep fake content, which undermines the reliable knowledge base that future generations need. For the sake of the planet and people on it, we have to address these issues.",
  },
  {
    id: "Solution",

    text: "Our purpose-driven technology ecosystem makes learning and problem-solving engaging and effective. It offers immersive and gamified impact education, a means for global collaboration, and showcases projects and innovations aligned with the Sustainable Development Goals (SDGs). By using ethical AI, trusted content, impact rewards, and digital twins, we ensure that all virtual activities lead to real-world positive outcomes, supported by blockchain for integrity, ownership, authenticity, and individual empowerment.",
    title: "Solution",
    image: "/images/blog/solution.jpg",
    date: "22 Feb",
    time: "3 min read",
  },
  {
    id: "2The biggest moves in Impact DOTs, Bitcoin, crypto rules",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "The biggest moves in Impact DOTs, Bitcoin, crypto rules",
    image: "/images/blog/post_4.jpg",
    date: "18 Jan",
    time: "3 min read",
  },
  {
    id: "3Incredible Amount of Developer Energy' in Web3",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "Incredible Amount of Developer Energy' in Web3",
    image: "/images/blog/post_5.jpg",
    date: "15 Jan",
    time: "3 min read",
  },
  {
    id: "4Inflation is up, it matters: High prices plague Biden's",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "Inflation is up, it matters: High prices plague Biden's",
    image: "/images/blog/post_6.jpg",
    date: "23 Dec",
    time: "3 min read",
  },
  {
    id: "6Mint your own Tezos collections",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "Mint your own Tezos collections",
    image: "/images/blog/post_1.jpg",
    date: "5 Feb",
    time: "3 min read",
  },
  {
    id: "7Mint your own Tezos collections",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "Mint your own Tezos collections",
    image: "/images/blog/post_2.jpg",
    date: "5 Feb",
    time: "3 min read",
  },
  {
    id: "8List your collection for secondary sales",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "List your collection for secondary sales",
    image: "/images/blog/post_3.jpg",
    date: "22 Feb",
    time: "3 min read",
  },
  {
    id: "9The biggest moves in Impact DOTs, Bitcoin, crypto rules",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "The biggest moves in Impact DOTs, Bitcoin, crypto rules",
    image: "/images/blog/post_4.jpg",
    date: "18 Jan",
    time: "3 min read",
  },
  {
    id: "10Incredible Amount of Developer Energy' in Web3",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "Incredible Amount of Developer Energy' in Web3",
    image: "/images/blog/post_5.jpg",
    date: "15 Jan",
    time: "3 min read",
  },
  {
    id: "11Inflation is up, it matters: High prices plague Biden's",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "Inflation is up, it matters: High prices plague Biden's",
    image: "/images/blog/post_6.jpg",
    date: "23 Dec",
    time: "3 min read",
  },
];

const single_news_data = [
  {
    id: "post_1",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "Mint your own Tezos collections",
    image: "/images/blog/post_1.jpg",
    date: "5 Feb",
    time: "3 min read",
    subImages: ["/images/blog/gallery_1.jpg", "/images/blog/gallery_2.jpg"],
    authorImage: "/images/blog/author_large.jpg",
    authorName: "Deothemes",
  },
  {
    id: "post_2",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "Mint your own Tezos collections",
    image: "/images/blog/post_2.jpg",
    date: "5 Feb",
    time: "3 min read",
    subImages: ["/images/blog/gallery_1.jpg", "/images/blog/gallery_2.jpg"],
    authorImage: "/images/blog/author_large.jpg",
    authorName: "Deothemes",
  },
  {
    id: "post_3",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "List your collection for secondary sales",
    image: "/images/blog/post_3.jpg",
    date: "22 Feb",
    time: "3 min read",
    subImages: ["/images/blog/gallery_1.jpg", "/images/blog/gallery_2.jpg"],
    authorImage: "/images/blog/author_large.jpg",
    authorName: "Deothemes",
  },
  {
    id: "post_4",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "The biggest moves in Impact DOTs, Bitcoin, crypto rules",
    image: "/images/blog/post_4.jpg",
    date: "18 Jan",
    time: "3 min read",
    subImages: ["/images/blog/gallery_1.jpg", "/images/blog/gallery_2.jpg"],
    authorImage: "/images/blog/author_large.jpg",
    authorName: "Deothemes",
  },
  {
    id: "post_5",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "Incredible Amount of Developer Energy' in Web3",
    image: "/images/blog/post_5.jpg",
    date: "15 Jan",
    time: "3 min read",
    subImages: ["/images/blog/gallery_1.jpg", "/images/blog/gallery_2.jpg"],
    authorImage: "/images/blog/author_large.jpg",
    authorName: "Deothemes",
  },
  {
    id: "post_6",
    text: "Since we launched Tezos at the end of 2021, many awesome creators...",
    title: "Inflation is up, it matters: High prices plague Biden's",
    image: "/images/blog/post_6.jpg",
    date: "23 Dec",
    time: "3 min read",
    subImages: ["/images/blog/gallery_1.jpg", "/images/blog/gallery_2.jpg"],
    authorImage: "/images/blog/author_large.jpg",
    authorName: "Deothemes",
  },
];

export { news_data, single_news_data };
