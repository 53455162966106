import React from "react";
import { news_data } from "../../data/news_data";
import HeadLine from "../headLine";
import Image from "next/image";
import News_item from "./news_item";

const About_news = () => {
  return (
    <>
      {/* <!-- Latest Posts --> */}
      <section className="relative">
        <div className="container">
          <News_item data={news_data.slice(1, 3)} />
        </div>
      </section>
      {/* <!-- end latest posts --> */}
    </>
  );
};

export default About_news;
