const features = [
  {
    id: 1,
    image: "/images/wallets/EXPERIENCES.svg",
    title: "EXPERIENCES",
    description:
      "Engage in educational simulations and experiences in the ecosystem",
    icon: "code",
    bgColor: "bg-purple-100",
    iconColor: "text-purple-600",
  },
  {
    id: 2,
    image: "/images/wallets/COLLABORATIONS.svg",
    title: "COLLABORATIONS",
    description:
      "Participate in co-creation challenges and collaborate to problem-solve",
    icon: "lightbulb",
    bgColor: "bg-blue-100",
    iconColor: "text-blue-600",
  },
  {
    id: 3,
    image: "/images/wallets/SHOWCASES.svg",
    title: "SHOWCASES",
    description:
      "Explore branded environments demonstrating ESG commitments & impact actions",
    icon: "check-circle",
    bgColor: "bg-yellow-100",
    iconColor: "text-yellow-600",
  },
];
export { features };
